import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "innerChat" ]

  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    var that = this;

    setTimeout(function() {
      that.element.scrollTop = that.innerChatTarget.offsetHeight;
    }, 250);
  }
}
