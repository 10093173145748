import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["sendButton"]
  static classes = ["highlight"]

  messageChange(e) {
    if (e.target.value === "") {
      this.disableSendButton()
    } else {
      this.enableSendButton()
    }
  }

  enableSendButton() {
    this.sendButtonTarget.disabled = false
  }

  disableSendButton() {
    this.sendButtonTarget.disabled = true
  }

  removeHighlight() {
    this.element.classList.remove(this.highlightClass)
  }

  highlight() {
    this.element.classList.add(this.highlightClass)
  }
}
