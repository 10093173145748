import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["submitButton", "input"]

  connect() {
    this.update()
  }

  update() {
    const hasValues = this.inputTargets.some( input => {
      return input.value !== ""
    })

    if (hasValues) {
      this.enableSubmit()
    } else {
      this.disableSubmit()
    }
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true
  }
}
