import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["fileInput", "image", "newImage"]

  connect() {
    this.fileInputTarget.onchange = (event) => {
      let files = event.target.files;
      let image = files[0]
      let reader = new FileReader();

      var that = this
      reader.onload = function(file) {
        that.newImageTargets.forEach((element) => {
          element.src = file.target.result
        });
      }
      reader.readAsDataURL(image);

      this.imageTargets.forEach((element) => {
        element.classList.add('hidden')
      });

      this.newImageTargets.forEach((element) => {
        element.classList.remove('hidden')
        // Show parent ring wrapper if hidden
        if (element.parentElement.classList.contains('hidden')) {
          element.parentElement.classList.remove('hidden')
        }
      });
    }
  }
}
