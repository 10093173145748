import CharacterCounter from "stimulus-character-counter"

export default class extends CharacterCounter {
  static values = {
    max: Number,
    warningClasses: { type: Array, default: ["text-brand-red", "font-semibold"] }
  }
  static targets = [ "max"]

  connect() {
    super.connect()

    this.maxTarget.innerHTML = this.maxValue.toString()
  }

  update() {
    super.update()

    this.count > this.maxValue ? this.addWarningClass() : this.removeWarningClass()
  }

  addWarningClass() {
    this.warningClassesValue.forEach((warningClass) => {
      this.counterTarget.classList.add(warningClass)
    });
  }

  removeWarningClass() {
    this.warningClassesValue.forEach((warningClass) => {
      this.counterTarget.classList.remove(warningClass)
    });
  }
}
