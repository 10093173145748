import { Controller } from '@hotwired/stimulus'
import { useHover } from 'stimulus-use'

export default class extends Controller {
  static classes = ["active"]

  connect() {
    useHover(this, { element: this.element });
  }

  mouseEnter() {
    this.element.classList.add(this.activeClass)
  }

  mouseLeave() {
    this.element.classList.remove(this.activeClass)
  }
}
