import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "priceIdInput"]

  select(e) {
    const priceId = e.currentTarget.dataset.priceId;
    this.priceIdInputTarget.value = priceId
  }
}
