import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["slider", "value", "minLabel", "maxLabel", "valueLabel"]

  connect() {
    this.updateValue()
  }
  updateValue() {
    this.valueTarget.innerText = this.sliderTarget.value
    const target = this.sliderTarget
    const min = target.min
    const max = target.max
    const val = target.value
    const valuePercentage = (val - min) * 100 / (max - min)

    // Move progress bar
    this.sliderTarget.style.backgroundSize = valuePercentage + '% 100%'

    // Reposition value label
    const offSetValueLabel = (this.valueLabelTarget.offsetWidth * (valuePercentage / 100))
    this.valueLabelTarget.style.left = "calc(" + valuePercentage + "% - " + offSetValueLabel + "px)"

    // Hide/show min/max labels
    if (valuePercentage <= 6) {
      this.minLabelTarget.classList.add("hidden")
      this.maxLabelTarget.classList.remove("hidden")
    } else if (valuePercentage >= 90){
      this.minLabelTarget.classList.remove("hidden")
      this.maxLabelTarget.classList.add("hidden")
    } else {
      this.minLabelTarget.classList.remove("hidden")
      this.maxLabelTarget.classList.remove("hidden")
    }
  }
}
