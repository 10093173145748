import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["submitButton", "submitLabel", "processingLabel"]

  showProcessing() {
    this.submitLabelTarget.classList.add("hidden")
    this.processingLabelTarget.classList.remove("hidden")
    this.submitButtonTarget.disabled = true
  }
}
