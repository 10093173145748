import { Controller } from "@hotwired/stimulus"
import * as UpChunk from '@mux/upchunk';

export default class extends Controller {
  static targets = [
    "fileInput",
    "videoIdInput",
    "uploadButtons",
    "progressSection",
    "progressLabel",
    "progressBar",
    "deleteButton",
    "videoPlayer",
    "submitButton"
  ]

  static values = {
    requestId: String,
    messageId: String,
    videoId: String,
    videoRequired: { type: Boolean, default: false },
    createUploadUrlPath: String,
    deleteVideoPath: String
  }

  connect() {
    this.upload = null;

    this.fileInputTarget.onchange = () => {
      this.showVideoPlayerPreview();
      if (this.progressSectionTarget.classList.contains('hidden')) {
        this.showUploadProgress();
      }

      const getUploadUrl = () =>
        fetch(this.createUploadUrlPath,
          {
            method: 'POST',
            headers: {
              'X-CSRF-Token': this.CSRFToken,
              'Content-Type': 'application/json',
            },
          }
        ).
        then(res => {
          return res;
        })
        .then(res =>
            res.ok ? res.json() : console.error('Error getting upload URL')
        ).then(data => {
          this.videoIdInputTarget.value = data.id;

          return data.url
        });

      this.upload = UpChunk.createUpload({
        endpoint: getUploadUrl,
        file: this.fileInputTarget.files[0],
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      this.upload.on('error', err => {
        // TODO: Handle Errors
        console.error("Upload Error")
      });

      this.upload.on('progress', progress => {
        this.submitDisabled(true)

        const progressTruncated = Math.trunc(progress.detail)
        this.progressLabelTarget.innerHTML = progressTruncated
        this.progressBarTarget.style.width = `${progressTruncated}%`

        if (progress.detail == 100) {
          this.hideUploadProgress()
          this.submitDisabled(false)
        }
      });

      this.upload.on('success', () => {
        this.hideUploadProgress()
        this.submitDisabled(false)
        // TODO: Do we need to disconnect uploader? It seems to resume on turbo back navigation.
      });
    };
  }

  selectFile() {
    this.fileInputTarget.click();
  }

  deleteVideo() {
    fetch(this.deleteVideoPath, {
        method:'DELETE',
        headers: {
          'X-CSRF-Token': this.CSRFToken,
          'Content-Type': 'application/json',
        },
    }).then(res=>{
      if (res.ok) {
        this.videoIdInputTarget.value = "";
        this.fileInputTarget.value = "";
        this.cancelUpload();

        this.showUploadButtons();
        this.hideUploadProgress();

        this.videoPlayerController.removeVideo();
        this.submitDisabled(this.videoRequiredValue)
      } else {
        // TODO: Handle errors
      }
    })
  }

  cancelUpload() {
    if (this.upload != null) {
      this.upload.abort();
    }
  }

  showVideoPlayerPreview() {
    let file = this.fileInputTarget.files[0];
    let blobURL = URL.createObjectURL(file);

    this.updateVideoPlayerSrc(blobURL)
  }

  showUploadProgress() {
    this.uploadButtonsTarget.classList.add('hidden');
    this.progressSectionTarget.classList.remove('hidden');
    this.deleteButtonTarget.classList.remove('hidden');
    this.videoPlayerTarget.classList.remove('hidden');
  }

  showUploadButtons() {
    this.uploadButtonsTarget.classList.remove('hidden');
    this.videoPlayerTarget.classList.add('hidden');
    this.deleteButtonTarget.classList.add('hidden');
  }

  hideUploadProgress() {
    this.progressSectionTarget.classList.add('hidden');
  }

  submitDisabled(disabled) {
    this.submitButtonTarget.disabled = disabled
  }

  updateVideoPlayerSrc(videoUrl) {
    this.videoPlayerController.updateSrc(videoUrl)
  }

  get CSRFToken () {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  get videoPlayerController() {
    return this.application.getControllerForElementAndIdentifier(this.videoPlayerTarget, "video-player")
  }

  get createUploadUrlPath() {
    if (this.createUploadUrlPathValue.length !== 0) {
      return this.createUploadUrlPathValue
    } else {
      return `/requests/${this.requestIdValue}/messages/${this.messageIdValue}/video-uploads.json`
    }
  }

  get deleteVideoPath() {
    if (this.deleteVideoPathValue.length !== 0) {
      return `${this.deleteVideoPathValue}/${this.videoIdInputTarget.value}.json`
    } else {
      return `/requests/${this.requestIdValue}/messages/${this.messageIdValue}/videos.json`
    }
  }
}
