import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remove(e) {
    e.preventDefault()

    this.element.style.display = 'none'

    const destroyInput = this.element.querySelector("input[name*='_destroy']")
    destroyInput.value = '1'
  }
}
