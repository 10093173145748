import { Controller } from '@hotwired/stimulus'
import dayjs from "dayjs"
dayjs.extend(require('dayjs/plugin/advancedFormat'))

export default class extends Controller {
  static targets = ["label"]
  static values = {
    timestamp: Number,
    format: { type: String, default: "MMM D, h:mm A" }
  }

  connect() {
    this.displayDatetime()
  }

  displayDatetime() {
    let datetimeDisplay = dayjs.unix(this.timestampValue).format(this.formatValue)
    this.labelTarget.innerHTML = datetimeDisplay
  }
}
