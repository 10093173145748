import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "background",
    "switch"
  ]

  static classes = [
    "enabledBackground",
    "disabledBackground",
    "enabledSwitch",
    "disabledSwitch"
  ]

  static values = {
    enabled: { type: Boolean, default: false }
  }

  connect() {
    this.render()
    if (this.enabledValue) {
      this.enableToggleButton()
    }
    this.dispatchUpdateEvent()
  }

  toggle() {
    this.enabledValue = !this.enabledValue
    this.dispatchUpdateEvent()
    this.render()
  }

  dispatchUpdateEvent() {
    this.element.dispatchEvent(new CustomEvent("changeToggleSwitch", {
      detail: {
        enabled: this.enabledValue
      },
      bubbles: true
    }));
  }

  render() {
    if (this.enabledValue) {
      this.renderEnabled()
    } else {
      this.renderDisabled()
    }
  }

  enableToggleButton() {
    this.element.disabled = false
  }

  disableToggleButton() {
    this.element.disabled = true
  }

  renderEnabled() {
    this.backgroundTarget.classList.remove(...this.disabledBackgroundClasses)
    this.backgroundTarget.classList.add(...this.enabledBackgroundClasses)

    this.switchTarget.classList.remove(...this.disabledSwitchClasses)
    this.switchTarget.classList.add(...this.enabledSwitchClasses)
  }

  renderDisabled() {
    this.backgroundTarget.classList.remove(...this.enabledBackgroundClasses)
    this.backgroundTarget.classList.add(...this.disabledBackgroundClasses)

    this.switchTarget.classList.remove(...this.enabledSwitchClasses)
    this.switchTarget.classList.add(...this.disabledSwitchClasses)
  }
}
