import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["number", "result"]

  connect() {
    this.calculate()
  }

  calculateMonthlyFromWeekly() {
    const weeksPerMonth = 4.34524
    const result = this.product() * weeksPerMonth
    this.resultTarget.innerText = Math.round(result).toLocaleString("en");
  }

  calculate() {
    this.resultTarget.innerText = Math.round(this.product()).toLocaleString("en");
  }

  product() {
    return this.numberTargets.reduce((accumulator, element) => {
      return accumulator * Number(element.value)
    }, 1);
  }
}
