import { Controller } from '@hotwired/stimulus'
import dayjs from "dayjs"

export default class extends Controller {
  static values = {
    isSuggestingTimeSlots: Boolean,
    timeslots: Array,
    suggestedTimeSlots: Array,
    selectedDateClasses: { type: Array, default: ["bg-brand-yellow"] },
    selectedTimeslotClasses: { type: Array, default: ["bg-brand-yellow", "bg-opacity-25", "border-brand-yellow", "border-2"] }
  }
  static targets = [
    "dateTemplate",
    "timeSlotTemplate",
    "suggestedTimeSlotTemplate",
    "selectDateContainer",
    "selectTimeslotContainer",
    "suggestedTimeSlotsInput",
    "requestedStartAtInput",
    "requestedEndAtInput",
    "suggestedToggle",
    "submitButton"
  ]

  connect() {
    // Can't mutate value array, so copy it.
    this.suggestedTimeSlots = [...this.suggestedTimeSlotsValue]
    this.enabled = false
    if (this.suggestedTimeSlots.length == 0) {
      this.resetAndRender()
    }
  }

  render() {
    Object.entries(this.availableDays).forEach(day => {
      const [key, timeslots] = day;

      const date = dayjs(key)
      this.renderDate(date)
    });

    let slides = this.element.querySelectorAll(".swiper-slide")
    if (slides.length > 0) {
      this.selectDateElement(slides[0])
    }
  }

  addSuggestedTimeSlot(e) {
    const timeSlotElement = e.currentTarget

    if (JSON.parse((timeSlotElement.dataset.suggested))) {
      return true
    }

    let timeSlot = {
      start: parseInt(timeSlotElement.dataset.start),
      end: parseInt(timeSlotElement.dataset.end),
      suggested: true
    }

    this.suggestedTimeSlotsObject[this.suggestedTimeSlotKey(timeSlot)] = true

    let index = this.suggestedTimeSlots.findIndex(ts => {
      return ts.start >= timeSlot.start
    })

    index = (index == -1) ? this.suggestedTimeSlots.length : index

    this.suggestedTimeSlots.splice(Math.max(0, index), 0, timeSlot)

    timeSlotElement.parentNode.replaceChild(this.timeSlotTemplate(timeSlot), timeSlotElement);

    this.enableToggleButton()
    this.updateSubmitDisabled()

    this.updateSuggestedTimeSlotsInput()
  }

  removeSuggestedTimeSlot(e) {
    const timeSlotElement = e.currentTarget.closest(".time-slot")

    const timeSlot = {
      start: parseInt(timeSlotElement.dataset.start),
      end: parseInt(timeSlotElement.dataset.end),
      suggested: false
    }

    const start = dayjs.unix(timeSlot.start)
    const date = start.second(0).minute(0).hour(0)
    const key = date.toISOString()

    // Remove from suggestedTimeSlotObject
    delete this.suggestedTimeSlotsObject[this.suggestedTimeSlotKey(timeSlot)];

    // remove from suggestedTimeSlot
    this.suggestedTimeSlots = this.suggestedTimeSlots.filter(slot => {
      return !(slot.start == timeSlot.start && slot.end == timeSlot.end)
    });

    // Remove timeSlot from available days..
    this.availableDays[key] = this.availableDays[key].filter(slot => {
      return !(slot.start == timeSlot.start && slot.end == timeSlot.end)
    });

    if (this.suggestedToggleController.enabledValue) {
      timeSlotElement.remove()

      if (this.availableDays[key].length == 0) {
        if (Object.keys(this.availableDays).length  - 1 > 0) {
          // There are other suggested days
          this.resetAndRender()
        } else {
          this.suggestedToggleTarget.click()
        }
      }
    } else {
      timeSlotElement.parentNode.replaceChild(this.timeSlotTemplate(timeSlot), timeSlotElement);
    }

    if (this.suggestedTimeSlots.length == 0) {
      this.disableToggleButton()
    }
    this.updateSubmitDisabled()

    this.updateSuggestedTimeSlotsInput()
  }

  renderDate(date) {
    const template = this.dateTemplateTarget.content.cloneNode(true);

    let dd = template.querySelectorAll(".date-day")
    dd[0].textContent = date.date()

    let detail = template.querySelectorAll(".date-detail")
    detail[0].textContent = date.format("MMM, ddd")

    template.firstElementChild.dataset.date = date.toISOString()

    this.selectDateContainerTarget.append(template)
  }

  renderTimeslots(date) {
    this.selectTimeslotContainerTarget.innerHTML = ""

    this.availableDays[date.toISOString()].forEach(timeSlot => {
      this.selectTimeslotContainerTarget.append(this.timeSlotTemplate(timeSlot))
    })
  }

  timeSlotTemplate(timeSlot) {
    const isSuggested = (this.suggestedTimeSlotKey(timeSlot) in this.suggestedTimeSlotsObject)
    const template = (isSuggested ? this.suggestedTimeSlotTemplateTarget.content.cloneNode(true)
      : this.timeSlotTemplateTarget.content.cloneNode(true))

    template.firstElementChild.dataset.start = timeSlot.start
    template.firstElementChild.dataset.end = timeSlot.end
    template.firstElementChild.dataset.suggested = isSuggested

    let detail = template.querySelectorAll(".timeslot-detail")
    const start = dayjs.unix(timeSlot.start)
    const end = dayjs.unix(timeSlot.end)

    detail[0].textContent = `${start.format("h:mm A")} - ${end.format("h:mm A")}`

    if (this.isSuggestingTimeSlotsValue && isSuggested) {
      this.addSelectedTimeSlotClasses(template.firstElementChild)
    }

    return template
  }

  updateSubmitDisabled() {
    if (this.isSuggestingTimeSlotsValue) {
      this.submitDisabled(this.suggestedTimeSlots.length == 0)
    }
  }

  updateSuggested(e) {
    this.enabled = e.detail.enabled

    this.resetAndRender()
  }

  resetAndRender() {
    this.resetTimeSlots()

    if (this.enabled) {
      this.setSuggestedTimeSlots()
    } else {
      this.setAvailabileTimeSlots()
    }

    this.selectDateContainerTarget.innerText = ""

    this.render()
  }

  resetTimeSlots() {
    this.availableDays = {}

    // Setup suggest timeslots
    this.suggestedTimeSlotsObject = {}
    this.suggestedTimeSlots.forEach(timeSlot => {
      this.suggestedTimeSlotsObject[this.suggestedTimeSlotKey(timeSlot)] = true
    })

    this.submitDisabled(true)
    this.updateSuggestedTimeSlotsInput()
  }

  setSuggestedTimeSlots() {
    this.setTimeSlots(this.suggestedTimeSlots)
  }

  setAvailabileTimeSlots() {
    this.setTimeSlots(this.timeslotsValue)
  }

  setTimeSlots(timeSlots) {
    timeSlots.forEach((timeslot) => {
      const start = dayjs.unix(timeslot.start)
      const date = start.second(0).minute(0).hour(0)
      const key = date.toISOString()

      if (!this.availableDays.hasOwnProperty(key)) {
        this.availableDays[key] = []
      }

      this.availableDays[key].push(timeslot)
    })
  }

  selectDate(e) {
    this.selectDateElement(e.currentTarget)
  }

  selectDateElement(element) {
    if (!this.isSuggestingTimeSlotsValue) {
      this.submitDisabled(true)
    }

    this.resetTimeslotInputs()

    if (this.hasOwnProperty("selectedDateElement")) {
      this.selectedDateElement.classList.remove(...this.selectedDateClassesValue)
    }

    this.selectedDateElement = element
    this.selectedDateElement.classList.add(...this.selectedDateClassesValue)

    const date = dayjs(this.selectedDateElement.dataset.date)
    this.renderTimeslots(date)
  }

  selectTimeSlot(e) {
    if (this.hasOwnProperty("selectedTimeslotElement")) {
      this.selectedTimeslotElement.classList.remove(...this.selectedTimeslotClassesValue)
    }
    this.selectedTimeslotElement = e.currentTarget
    this.selectedTimeslotElement.classList.add(...this.selectedTimeslotClassesValue)

    this.setTimeslotInputs(this.selectedTimeslotElement.dataset)

    this.submitDisabled(false)
  }

  remoteSelectedTimeSlotClasses(element) {
    element.classList.remove(...this.selectedTimeslotClassesValue)
  }

  addSelectedTimeSlotClasses(element) {
    element.classList.add(...this.selectedTimeslotClassesValue)
  }

  setTimeslotInputs(timeslot) {
    if (!this.isSuggestingTimeSlotsValue) {
      this.requestedStartAtInputTarget.value = dayjs.unix(timeslot.start).toISOString()
      this.requestedEndAtInputTarget.value = dayjs.unix(timeslot.end).toISOString()
    }
  }

  resetTimeslotInputs() {
    if (!this.isSuggestingTimeSlotsValue) {
      this.requestedStartAtInputTarget.value = ""
      this.requestedEndAtInputTarget.value = ""
    }
  }

  updateSuggestedTimeSlotsInput() {
    if (this.hasSuggestedTimeSlotsInputTarget) {
      this.suggestedTimeSlotsInputTarget.value = encodeURIComponent(JSON.stringify(
        this.suggestedTimeSlots.map(ts => {
          return {
            start_at: dayjs.unix(ts.start).toISOString(),
            end_at: dayjs.unix(ts.end).toISOString()
          }
        })
      ))
    }
  }

  submitDisabled(disabled) {
    this.submitButtonTarget.disabled = disabled
  }

  suggestedTimeSlotKey(timeSlot) {
    return `${timeSlot.start}-${timeSlot.end}`
  }

  enableToggleButton() {
    this.suggestedToggleController.enableToggleButton()
  }

  disableToggleButton() {
    this.suggestedToggleController.disableToggleButton()
  }

  get suggestedToggleController() {
    return this.application.getControllerForElementAndIdentifier(this.suggestedToggleTarget, "toggle-switch")
  }
}
