import { Controller } from '@hotwired/stimulus'
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["sortable", "orderedIdsInput"]

  connect() {
    this.sortable = Sortable.create(this.sortableTarget, {
      group: 'shared',
      handle: '.sortable-handle',
      animation: 150,
      onEnd: this.end.bind(this)
    })

    this.updateOrder()
  }

  end(event) {
    this.updateOrder()
  }

  updateOrder() {
    this.orderedIdsInputTarget.value = this.sortable.toArray()
  }
}
