import { Controller } from '@hotwired/stimulus'
import dayjs from "dayjs"
dayjs.extend(require('dayjs/plugin/relativeTime'))

export default class extends Controller {
  static values = { timestamp: Number }

  connect() {
    this.load()
  }

  load() {
    const datetime = dayjs.unix(this.timestampValue)
    const datetimeDisplay = datetime.format("MMM D, h:mm A")
    this.element.dateTime = datetimeDisplay
    this.element.title = datetimeDisplay
    this.element.innerHTML = datetime.fromNow(true)
  }
}
