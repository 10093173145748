import { Controller } from '@hotwired/stimulus'
import {enter, leave} from 'el-transition'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["menu", "button"]

  connect() {
    useClickOutside(this, { element: this.contentTarget })
  }

  clickOutside(event) {
    leave(this.menuTarget)
  }

  toggleMenu() {
    if (this.menuTarget.classList.contains('hidden')) {
      enter(this.menuTarget)
    } else {
      leave(this.menuTarget)
    }
  }
}
