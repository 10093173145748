import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'source', 'successLabel']
  static values = {
    successDuration: Number
  }

  connect() {
    if (!this.hasButtonTarget) {
      return;
    }

    this.originalText = this.successLabelTarget.innerHTML
  }

  copy(e) {
    e.preventDefault()

    navigator.clipboard.writeText(this.sourceTarget.value);

    this.copied()
  }

  copied() {
    if (!this.hasButtonTarget) {
      return
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.successLabelTarget.innerText = this.data.get('successContent')

    this.timeout = setTimeout(() => {
      this.successLabelTarget.innerHTML = this.originalText
    }, this.successDurationValue)
  }
}
