import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "placeholder", "preview"]

  connect() {
    this.checkPreview()
  }

  inputChange(e) {
    this.checkPreview()
  }

  checkPreview() {
    if (this.inputTarget.value === "") {
      this.hidePreview()
    } else {
      this.showPreview()
    }

  }

  showPreview() {
    this.placeholderTarget.classList.add("hidden")
    this.previewTarget.classList.remove("hidden")
  }

  hidePreview() {
    this.previewTarget.classList.add("hidden")
    this.placeholderTarget.classList.remove("hidden")
  }
}
