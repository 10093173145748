// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Carousel from "stimulus-carousel"
import ScrollTo from "stimulus-scroll-to"
import { Alert, Modal, Popover, Tabs } from "tailwindcss-stimulus-components"
import dayjs from "dayjs"
import NestedForm from "stimulus-rails-nested-form"
import Autosave from "stimulus-rails-autosave"

import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css'; 

const application = Application.start()
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register("nested-form", NestedForm)
application.register('popover', Popover)
application.register("carousel", Carousel)
application.register("scroll-to", ScrollTo)
application.register('modal', Modal)
application.register('dayjs', dayjs)
application.register('tabs', Tabs)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

const context = require.context("controllers", true, /_controller\.(js|ts)$/)
Stimulus.load(definitionsFromContext(context))
