import { Controller } from "@hotwired/stimulus"
import videojs from '@mux/videojs-kit';

export default class extends Controller {
  static targets = [ "videoTemplate", "player"]
  static values = {
    storyboardUrl: String
  }

  connect() {
    this.initializePlayer()
  }

  disconnect() {
    videojs(this.videoElement).dispose();
  }

  initializePlayer() {
    var template = this.videoTemplateTarget.content.cloneNode(true)
    this.element.append(template)

    this.video = videojs(this.videoElement, { controlBar: { "pictureInPictureToggle": false}}, function(){
    });

    if (this.hasStoryboardUrlValue) {
      this.video.timelineHoverPreviews({
        enabled: true,
        src: this.storyboardUrlValue
      });
    }
  }

  get videoElement() {
    return this.element.querySelector('video')
  }

  removeVideo() {
    this.video.pause();
    this.video.reset();
  }

  updateSrc(videoUrl) {
    this.video.src ({
      src: videoUrl,
      type: 'video/mp4'/*video type*/,
      fluid: true
    })
  }
}
