import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input"]

  focus() {
    if (this.hasInputTarget) {
      this.inputTarget.focus()
    }
  }
}
