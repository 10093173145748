import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static targets = ['container', 'frame', 'content', 'loadingIndicator']

  connect() {
    // hides content and shows loading indicator while frame src is loaded
    if (this.hasFrameTarget) {
      this.contentTarget.classList.add("hidden")
      this.loadingIndicatorTarget.classList.remove("hidden")

      this.frameTarget.addEventListener("turbo:frame-load", event => {
        this.loadingIndicatorTarget.classList.add("hidden")
        this.contentTarget.classList.remove("hidden")
      })
    }

    super.connect()
  }

  open(e) {
    const url = e.params.url

    if (url && this.hasFrameTarget) {
      this.frameTarget.src = url;
    }

    super.open(e)
  }

  close(e) {
    if (this.hasFrameTarget) {
      // Clear loaded frame content
      this.contentTarget.classList.add("hidden")
      this.loadingIndicatorTarget.classList.remove("hidden")

      this.frameTarget.src = ""
      this.frameTarget.textContent = ""
    }

    super.close(e)
  }

  loadFrame(url) {
    this.frameTarget.src = url;
  }
}
