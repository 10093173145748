import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["preview"]

  update(e) {
    var result = this.previewTargets.find(obj => {
      return obj.getAttribute("data-input-preview-name") === e.target.id
    })

    result.innerText = e.target.value
  }
}
