import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static values = {
    wrapperSelector: {
      type: String,
      default: '.nested-form-wrapper'
    },
    unavailableLabelSelector: {
      type: String,
      default: ".edit-availability-unavailable-wrapper"
    },
    startTimeSelector: {
      type: String,
      default: ".edit-availability-start-time"
    },
    endTimeSelector: {
      type: String,
      default: ".edit-availability-end-time"
    }
  }

  connect() {
    super.connect()
  }

  add(e) {
    // Last endTime that is not hidden
    const lastEndTime = Array.prototype.slice.call(this.element.querySelectorAll(this.endTimeSelectorValue))
      .filter((s) => {
        return s.getClientRects().length > 0
      }).at(-1)

    super.add(e)

    const label = this.element.querySelector(this.unavailableLabelSelectorValue)
    label.classList.add("hidden")

    if (lastEndTime !== null && lastEndTime !== undefined) {
      const newStartTime = [...this.element.querySelectorAll(this.startTimeSelectorValue)].at(-1)
      const newEndTime = [...this.element.querySelectorAll(this.endTimeSelectorValue)].at(-1)

      // New start time is one hour after last end time
      const minsToAdd = 60;
      const startTime = new Date(new Date("1970/01/01 " + lastEndTime.value).getTime() + minsToAdd * 60000)
        .toLocaleTimeString('en-UK', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
      newStartTime.value = startTime

      // New end time is one hour after new start time
      const endTime = new Date(new Date("1970/01/01 " + newStartTime.value).getTime() + minsToAdd * 60000)
        .toLocaleTimeString('en-UK', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
      newEndTime.value = endTime
    }

    this.dispatch("add", {})
  }

  remove(e) {
    super.remove(e)

    // Show unavailable label if all availabilies are hidden
    const availabilities = Array.prototype.slice.call(this.element.querySelectorAll(this.wrapperSelectorValue))
    const showUnavailable = availabilities.every((e) => {
      return e.getClientRects().length === 0
    })

    if (showUnavailable) {
      const label = this.element.querySelector(this.unavailableLabelSelectorValue)
      label.classList.remove("hidden")
    }

    this.dispatch("remove", {})
  }
}
