import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = { redirectPath: String, intervalSeconds: Number }

  connect() {
    this.beginRedirectCountdown()
  }

  disconnect() {
    clearTimeout(this.countdown)
  }

  beginRedirectCountdown() {
    var that = this
    this.countdown = setTimeout(function() {
      window.location.href = that.redirectPathValue
    }, that.intervalSecondsValue * 1000)
  }
}
