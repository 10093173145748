import { Controller } from '@hotwired/stimulus'

import dayjs from "dayjs"
dayjs.extend(require('dayjs/plugin/timezone'))

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.inputTarget.value = dayjs.tz.guess()
  }
}
