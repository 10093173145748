import { Controller } from "@hotwired/stimulus"

import videojs from '@mux/videojs-kit';
import RecordRTC from 'recordrtc';
import Record from 'videojs-record/dist/videojs.record.js';

export default class extends Controller {
  static targets = [ "player", "saveButton", "recordButton", "deleteButton",
    "cancelButton", "pauseButton", "resumeButton", "uploadButtons", "recorderSection", "countdownContainer", "durationRecordedLabel",
    "recordIndicator", "deviceSelectButton", "videoDeviceSelect", "audioDeviceSelect"]

  connect() {
   if (this.hasPlayerTarget) {
      this.initializePlayer()
    }
  }

  initializePlayer() {
    let that = this

    this.recorder = videojs(this.playerTarget, this.playerOptions, function() {
        // var msg = 'Using video.js ' + videojs.VERSION +
        //     ' with videojs-record ' + videojs.getPluginVersion('record') +
        //     ' and recordrtc ' + RecordRTC.version;
        // videojs.log(msg);
    });

    this.recorder.on('enumerateReady', function() {
      that.setDeviceOptions();
    });

    this.recorder.on('deviceReady', function() {
      that.recordButtonTarget.disabled = false
    });

    // user clicked the record button and started recording
    this.recorder.on('startRecord', function() {
    });

    // user clicked the record button and started recording
    this.recorder.on('timestamp', function() {
      const currentTime = that.recorder.record().streamCurrentTime
      that.durationRecordedLabelTarget.innerText = that.recorder.record()._formatTime(currentTime)
    });

    // user completed recording and stream is available
    this.recorder.on('finishRecord', function() {
      that.updateFileInput();
    });

    // error handling
    this.recorder.on('deviceError', function() {
      // TODO: Error handling "this.deviceErrorCode"
      //
    });

    this.recorder.on('error', function(element, error) {
      // TODO: Error Handling
    });

    // error handling
    this.recorder.on('enumerateError', function() {
      //TODO: Error handleing
    });
  }

  record() {
    this.startRecordCountdown(() => {
      this.showRecordIndicator(true)
      this.recorder.record().start()
    });

    // Device cannot be changed without stopping existing recorder
    this.deviceSelectButtonTarget.classList.add('hidden');
    this.recordButtonTarget.classList.add('hidden')
  }

  startRecordCountdown(finishCallback) {
    let that = this;
    var countdownSeconds = 1;

    this.countdownContainerTarget.innerHTML = 3
    this.countdownContainerTarget.classList.remove('hidden')

    this.downloadTimer = setInterval(function(){
      if(countdownSeconds >= 3){
        clearInterval(that.downloadTimer);
        finishCallback();

        that.countdownContainerTarget.classList.add('hidden')
        that.cancelButtonTarget.classList.add('hidden')

        that.deleteButtonTarget.classList.remove('hidden')
        that.pauseButtonTarget.classList.remove('hidden')
        that.saveButtonTarget.classList.remove('hidden')
      }

      that.countdownContainerTarget.innerHTML = 3 - countdownSeconds

      countdownSeconds += 1
    }, 1000);
  }

  setDeviceOptions() {
    var devices = this.recorder.record().devices;
    var node, textnode;

    // remove existing list items
    while (this.videoDeviceSelectTarget.firstChild) {
        this.videoDeviceSelectTarget.removeChild(this.videoDeviceSelectTarget.firstChild);
    }
    while (this.audioDeviceSelectTarget.firstChild) {
        this.audioDeviceSelectTarget.removeChild(this.audioDeviceSelectTarget.firstChild);
    }

    this.videoDeviceSelectTarget.removeAttribute("disabled");
    this.audioDeviceSelectTarget.removeAttribute("disabled");

    // The user must have already granted permission to the page
    // to use the media devices in order to get the device label
    // populated. When served over HTTPS, the browser will remember
    // permission granted on subsequent loads, so the permission
    // will have been granted before requesting media. When using
    // HTTP, not HTTPS, the getUserMedia request must be made and
    // accepted before enumerateDevices will populate labels.
    var that = this;
    devices.forEach(function(device) {
      if (device.kind === "videoinput") {
        node = document.createElement('option');
        node.innerText = device.label
        node.value = device.deviceId

        if (typeof that.recorder.record().recordVideo.deviceId !== 'undefined') {
          node.selected = device.deviceId == that.recorder.record().recordVideo.deviceId.exact
        }
        that.videoDeviceSelectTarget.append(node)
      }

      if (device.kind === "audioinput") {
        node = document.createElement('option');
        node.innerText = device.label
        node.value = device.deviceId

        if (typeof that.recorder.record().recordAudio.deviceId !== 'undefined') {
          node.selected = device.deviceId == that.recorder.record().recordAudio.deviceId.exact;
        }
        that.audioDeviceSelectTarget.append(node)
      }
    });

    if (this.videoDeviceSelectTarget.length === 0) {
      node = document.createElement('option');
      node.innerText = "No Video Devices Available"
      this.videoDeviceSelectTarget.append(node)
      this.videoDeviceSelectTarget.setAttribute("disabled", "disabled");
    }

    if (this.audioDeviceSelectTarget.length === 0) {
      node = document.createElement('option');
      node.innerText = "No Audio Devices Available"
      this.audioDeviceSelectTarget.append(node)
      this.audioDeviceSelectTarget.setAttribute("disabled", "disabled");
    }
  }

  changeVideoDevice(event) {
    this.recorder.record().setVideoInput(event.target.value)
  }

  changeAudioDevice(event) {
    this.recorder.record().setAudioInput(event.target.value)
  }

  pause() {
    this.recorder.record().pause();

    this.pauseButtonTarget.classList.add('hidden')
    this.resumeButtonTarget.classList.remove('hidden')
    this.saveButtonTarget.classList.remove('hidden')

    this.showRecordIndicator(false);
  }

  delete() {
    this.recorder.record().stop();
    this.hideRecorder()
  }

  enumerateDevices() {
    this.recorder.record().enumerateDevices();
  }

  resume() {
    this.startRecordCountdown(() => {
      this.showRecordIndicator(true)
      this.recorder.record().resume()
    });

    this.resumeButtonTarget.classList.add('hidden')
    this.saveButtonTarget.classList.add('hidden')
    this.deleteButtonTarget.classList.remove('hidden')
  }

  save() {
    this.recorder.record().stop();
  }

  showRecordIndicator(isShown) {
    if (isShown) {
      this.recordIndicatorTarget.classList.remove('hidden')
    } else {
      this.recordIndicatorTarget.classList.add('hidden')
    }
  }

  updateFileInput() {
    let data = this.recorder.recordedData
    let file = new File(
      [data],
      data.name,
      {
        type: data.type,
        lastModified: new Date().getTime()
      }
    );

    // Add file to uploader fileinput
    let container = new DataTransfer();
    container.items.add(file);
    this.videoUploaderController.fileInputTarget.files = container.files

    // Trigger change event
    var event = new Event('change');
    this.videoUploaderController.fileInputTarget.dispatchEvent(event);

    this.hideRecorder();
  }

  hideUploadButtons() {
    if (this.hasUploadButtonsTarget) {
      this.uploadButtonsTarget.classList.add('hidden')
    }
  }

  showUploadButtons() {
    if (this.hasUploadButtonsTarget) {
      this.uploadButtonsTarget.classList.remove('hidden')
    }
  }

  showRecorder() {
    if (this.hasRecorderSectionTarget) {
      this.recorderSectionTarget.classList.remove('hidden')
    }

    this.resetInitialButtonState();

    this.recorder.record().getDevice();
  }

  resetInitialButtonState() {
    this.recordButtonTarget.classList.remove('hidden')
    this.cancelButtonTarget.classList.remove('hidden')
    this.deleteButtonTarget.classList.add('hidden')
    this.countdownContainerTarget.classList.add('hidden')
    this.pauseButtonTarget.classList.add('hidden')
    this.saveButtonTarget.classList.add('hidden')
    this.resumeButtonTarget.classList.add('hidden')
    this.showRecordIndicator(false)
    this.deviceSelectButtonTarget.classList.remove('hidden');
  }

  hideRecorder() {
    if (this.hasRecorderSectionTarget) {
      this.recorderSectionTarget.classList.add('hidden')
    }

    clearInterval(this.downloadTimer);
    this.recorder.record().stopDevice();
    this.recorder.record().reset()
  }

  get videoUploaderController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "video-uploader")
  }

  get playerOptions () {
    return {
      controls: false,
      bigPlayButton: false,
      controlBar: {
        deviceButton: false,
        recordToggle: false,
        recordIndicator: false
      },
      fluid: true,
      plugins: {
        record: {
          videoMimeType: this.videoMimeType,
          audio: true,
          video: true,
          maxLength: 3600, // 60 minutes
          debug: false,
          timeSlice: 1000, // Fire timestamp every second
          video: {
            width: { min: 640, ideal: 640, max: 1280 },
            height: { min: 360, ideal: 360, max: 920 },
          },
        }
      }
    }
  }

  get videoMimeType () {
    let isSafari = navigator.vendor.match(/apple/i) &&
                 !navigator.userAgent.match(/crios/i) &&
                 !navigator.userAgent.match(/fxios/i) &&
                 !navigator.userAgent.match(/Opera|OPT\//);

    if (isSafari) {
      return "video/mp4;codecs:h264";
    } else {
      return "video/webm;codecs=vp8";
    }
  }
}
