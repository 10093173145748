import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["template", "target"]
  static values = {
    wrapperSelector: String
  }

  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove (e)  {
    e.preventDefault()

    const wrapper = e.target.closest(this.wrapperSelectorValue)
    wrapper.remove()
  }
}
