import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "itemsContainer", "itemTemplate"]

  connect() {
    this.intiailizeContainer()
  }

  intiailizeContainer() {
    this.itemsArray().forEach(item => this.addItemElement(item))
  }

  preventEmptyItem(event) {
    if (event.key === " " || event.key === ",") {
      if (event.target.value.trim().length === 0) {
        event.preventDefault();
        return false;
      }
    }

    // Prevent form submission
    if (event.key === "Enter") {
      event.preventDefault();
      return false
    }
  }

  newItem(event) {
    if (event.key === "Enter" || event.key === ",") {
      if (this.addItem(event.target.value)) {
        event.target.value = ""
      } else {
        event.preventDefault()
      }
    }
  }

  addItem(item) {
    // Trim spaces to one, remove commas, and remove trailing whitespace
    item = item.replace(/\s+/g, ' ').replace(/,/g, '').trim()

    // Prevent empty element
    if (item === '' || item === ',') {
      return false;
    }

    var itemArray = this.itemsArray()

    // Prevent duplicates
    if (itemArray.includes(item)) {
      return false
    }

    itemArray.push(item)
    this.inputTarget.value = itemArray.join(",")
    this.addItemElement(item)

    return true
  }

  removeItem(event) {
    const itemContainer = event.target.closest("[data-list-form-target='itemContainer']")
    const item = itemContainer.querySelector('label').innerText

    var itemArray = this.itemsArray()
    var index = itemArray.indexOf(item);

    if (index !== -1) {
      itemArray.splice(index, 1);
    }

    this.inputTarget.value = itemArray.join(",")

    itemContainer.remove()
  }

  addItemElement(item){
    var template = this.itemTemplateTarget.content.cloneNode(true)
    template.querySelector('label').innerText = item
    this.itemsContainerTarget.append(template)
  }

  itemsArray() {
    return this.inputTarget.value
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item.length !== 0);
  }

}

