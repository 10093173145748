
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ["compacted", "expanded"]
  static targets = ['readMore', 'showLess']

  connect() {
    if ((this.element.scrollHeight - 20) > this.element.clientHeight) {
      this.readMoreTarget.classList.remove("hidden");
      this.element.classList.add(...this.compactedClasses)
    }
  }

  toggle() {
    this.compactedClasses.forEach(c => this.element.classList.toggle(c));
    this.expandedClasses.forEach(c => this.element.classList.toggle(c));

    this.readMoreTarget.classList.toggle("hidden")
    this.showLessTarget.classList.toggle("hidden")
  }
}
