import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "productIdInput", "requestTypeInput", "priceLabel" ]

  selectProduct(e) {
    const productId = e.currentTarget.dataset.productId;
    const productPrice = e.currentTarget.dataset.productPrice;
    const productType = e.currentTarget.dataset.productType;

    this.priceLabelTarget.innerHTML = productPrice;
    this.productIdInputTarget.value = productId
    this.requestTypeInputTarget.value = productType
  }
}
