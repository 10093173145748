import { Controller } from '@hotwired/stimulus'

import dayjs from "dayjs"
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))
dayjs.extend(require("dayjs/plugin/advancedFormat"))

export default class extends Controller {
  static targets = ["hide", "button", "datetimeInput", "timezoneInput", "timezoneLabel", "timezoneDetailLabel"]

  connect() {
    this.refreshSchedule();

    if (this.datetimeInputTarget.value == "") {
      // Set default input to 1 day and 10minutes from local system time
      var now = new Date();
      now.setDate(now.getDate() + 1);
      now.setMinutes(now.getMinutes() + 10 - now.getTimezoneOffset());
      this.datetimeInputTarget.value = now.toISOString().slice(0,16);
    } else {
      // Apply system local time offset to datetimeInput
      this.datetimeInputTarget.value = dayjs.utc(this.datetimeInputTarget.value).local().format().slice(0,16)
    }

    const timezoneName = dayjs.tz.guess();
    const timezoneShort = dayjs().tz(timezoneName).format('z');
    const utcOffset = dayjs().tz(timezoneName).format('Z');
    this.timezoneLabelTarget.innerText = timezoneShort;
    this.timezoneDetailLabelTarget.innerHTML = timezoneShort + ": " + timezoneName + "<br>UTC " + utcOffset;
    this.timezoneInputTarget.value = dayjs().utcOffset()
  }

  refreshSchedule() {
    if (this.datetimeInputTarget.disabled) {
      this.buttonTarget.innerText = "Publish now"
      this.hideTarget.classList.add("hidden")
    } else {
      this.buttonTarget.innerText = "Schedule"
      this.hideTarget.classList.remove("hidden")
    }
  }

  schedule() {
    this.datetimeInputTarget.disabled = false;
    this.refreshSchedule();
  }

  publishNow() {
    this.datetimeInputTarget.disabled = true;
    this.refreshSchedule();
  }
}
