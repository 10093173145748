// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "@rails/actiontext"
import "channels"
import "trix"

import dayjs from "dayjs"
dayjs.extend(require('dayjs/plugin/updateLocale'))
dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'just now',
    m: "1min",
    mm: "%dmin",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmo",
    y: "1y",
    yy: "%dy"
  }
})

import ahoy from "ahoy.js"
ahoy.trackSubmits('form[data-track-submit="true"]');

import { H } from 'highlight.run';
window.H = H;

Rails.start()
ActiveStorage.start()

import "stylesheets/application"
import "controllers"
