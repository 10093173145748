import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "submit"]

  static values = {
    editorId: String,
  }

  addCallToAction(e){
    e.preventDefault
    let formData = new FormData()
    formData.append("content", this.inputTarget.value)

    $.ajax({
      type: 'POST',
      processData: false,
      contentType: false,
      url: `/admin-dash/articles_call_to_actions`,
      data: formData,
      success: ({content, sgid}) => {
        this.editorController().insertAttachment(content, sgid);
      }
    })
  }

  editorController(){
    return this.application.getControllerForElementAndIdentifier(this.editorElement(), "admin--article-call-to-action")
  }

  editorElement(){
    return document.querySelector(this.editorElementName())
  }

  editorElementName(){
    return `#${this.editorIdValue}`
  }
}
