import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["hide"]
  static classes = ["hidden"]

  toggle() {
    this.hideTargets.forEach((t) => t.classList.toggle(this.hiddenClass));
  }
}
