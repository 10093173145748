import { Controller } from "stimulus"
import Trix from "trix"

export default class extends Controller {
  static targets = []

  static values = {
    categories: Array,
  }

  connect() {
    this.toolbarElement = this.element.previousSibling
    this.dialogsElement = this.toolbarElement.querySelector(".trix-dialogs")
    this.buttonGroupFileTools = this.toolbarElement.querySelector(".trix-button-group--file-tools")

    this.addButton()
    this.addDialog()
    this.eventListenerForButton()
  }

   addButton() {
    const buttonHTML = '<button type="button" class="trix-button article-call-to-action" data-trix-action="cta" title="Call to Action">CTA</button>'
    this.buttonGroupFileTools.insertAdjacentHTML("beforeend", buttonHTML)
   }

   addDialog() {
     // Change input to select
    const dialogHTML = `
<div class="trix-dialog trix-dialog--link" data-trix-dialog="cta" data-trix-dialog-attribute="cta" data-controller="admin--article-call-to-action-dialog" data-admin--article-call-to-action-dialog-editor-id-value="${this.element.editor.element.id}">
        <div class="trix-dialog__link-fields">
          <select name="cta" class="trix-input trix-input--dialog" placeholder="Enter 'expert_signup' or a category" data-trix-input="" data-admin--article-call-to-action-dialog-target="input" required data-trix-validate="">
            ${this.categoriesOptions()}
          </select>
          <div class="trix-button-group">
            <input type="button" class="trix-button trix-button--dialog" value="Submit" data-trix-method="setAttribute" data-admin--article-call-to-action-dialog-target="submit" data-action="click->admin--article-call-to-action-dialog#addCallToAction">
          </div>
        </div>
      </div>
    `
    this.dialogsElement.insertAdjacentHTML("beforeend", dialogHTML)
  }

  categoriesOptions() {
    return this.categoriesValue.map(category => {
      return `<option value="${category}">${category}</option>`
    }).join("")
  }

  show(e){
    const dialog = this.toolbarElement.querySelector('[data-trix-dialog="cta"]')
    const embedInput = this.dialogsElement.querySelector('[name="cta"]')
    if (event.target.classList.contains("trix-active")) {
      event.target.classList.remove("trix-active");
      dialog.classList.remove("trix-active");
      delete dialog.dataset.trixActive;
      embedInput.setAttribute("disabled", "disabled");
    } else {
      event.target.classList.add("trix-active");
      dialog.classList.add("trix-active");
      dialog.dataset.trixActive = "";
      embedInput.removeAttribute("disabled");
      embedInput.focus();
    }
  }

  eventListenerForButton() {
    this.toolbarElement.querySelector('[data-trix-action="cta"]').addEventListener("click", e => {
      this.show(e)
    })
  }

  insertAttachment(content, sgid){
    const attachment = new Trix.Attachment({content, sgid})
    this.element.editor.insertAttachment(attachment)
  }
}
